import { Slot } from '@radix-ui/react-slot';
import { clsx } from 'clsx/lite';
import { type ForwardedRef, type PropsWithChildren, forwardRef } from 'react';
import { isPlainObject, mapValues } from 'remeda';
import type { Schema } from 'type-fest';

import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';
import { containerStyle } from '../box/box.css.js';

export interface FlexProps
  extends PropsWithChildren<ElementProps<'div'> & RainbowSprinkles> {
  asChild?: boolean | undefined;
  isContainer?: boolean | undefined;
  inline?: boolean;
  isHidden?: Schema<RainbowSprinkles['display'], boolean>;
}

function Flex(
  { asChild, children, className, isHidden, inline, ...props }: FlexProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const Component = asChild ? Slot : 'div';
  const display = inline ? 'inline-flex' : 'flex';
  const rs = rainbowSprinkles({
    ...props,
    display:
      isPlainObject(isHidden) ?
        mapValues(isHidden, value => (value ? 'none' : display))
      : isHidden ? 'none'
      : display,
  });

  return (
    <Component
      className={clsx(rs.className, className, containerStyle)}
      ref={ref}
      style={rs.style}
      {...rs.otherProps}
    >
      {children}
    </Component>
  );
}

const _Flex = forwardRef(Flex);

export { _Flex as Flex };
